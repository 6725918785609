export enum DroitsEnum {
  // Gérer les équipes
  EQUIPES = "EQUIPES",
  // Gérer les utilisateurs
  ADMINISTRATION = "ADMINISTRATION",
  // Gérer les entreprises
  ADMINISTRATION_ENTREPRISE = "ADMINISTRATION_ENTREPRISE",
  // Gérer les Ra
  ADMINISTRATIONRA = "ADMINISTRATIONRA",
  UTILISATIONRA = "UTILISATIONRA",
  // Gérer les options
  OPTIONS = "OPTIONS",
  // Accès aux consignes
  CONSIGNES = "CONSIGNES",
  // Suivi entreprise
  ENTREPRISE_MANAG_TOTAL = "ENTREPRISE_MANAG_TOTAL",
  ENTREPRISE = "ENTREPRISE",
  DOSSIERS_MANAG_TOTAL = "DOSSIERS_MANAG_TOTAL",
  DOSSIER_NOTES = "DOSSIER_NOTES",
  DOSSIER_EDP = "DOSSIER_EDP",
  DOSSIER_PI = "DOSSIER_PI",
  DOSSIER_INFO_GENERAL = "DOSSIER_INFO_GENERAL",
  DOSSIER_INFO_CONTRAT = "DOSSIER_INFO_CONTRAT",
  DOSSIER_INFO_SANTE = "DOSSIER_INFO_SANTE",
  DPO = "DPO"
}